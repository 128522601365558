import { useCallback, useState } from 'react';
import { object } from 'yup';
import type { ObjectSchema, ObjectShape } from 'yup';

export const useUpdateFormSchema = <T extends ObjectShape, >(initialSchema: ObjectSchema<T>) => {
  const [schema, setSchema] = useState(initialSchema ?? null);
  const updateSchema = useCallback((newSchema) => {
    setSchema(oldSchema => {
      if (oldSchema) {
        return object().shape({
          ...oldSchema.fields,
          ...newSchema.fields,
        });
      }
      return newSchema;
    });
  }, []);

  return { schema, updateSchema };
};
