import { yupResolver } from '@hookform/resolvers/yup';
import { createContext, useCallback, useContext, useEffect, useMemo, useReducer } from 'react';
import {
  FormProvider as FormRCProvider,
  useForm as useRCForm,
  useFormContext as useRCFormContext,
} from 'react-hook-form';
import { FORM_ACTIONS } from '@/components/common/form/context/constants';
import { useUpdateFormSchema } from '@/components/common/form/hooks/useUpdateFormSchema';
import type { IFormContextInitialState, IFormFields, IFormProviderProps } from '@/components/common/form/types';
import {
  getFormDefaultValues,
  getFormField,
  getFormValidationSchema,
  getGroupedFields, getNextFormPageIndex,
  getPreviousFormPageIndex,
} from '@/components/common/form/utils/formUtils';
import type { Dispatch } from 'react';

const initialState: IFormContextInitialState = {
  activePage: 0,
  fields: [],
  getField: () => undefined,
  pagesCount: 0,
  groupedFields: [],
  updateSchema: () => undefined,
  disabledPages: [],
  getPreviousPageIndex: () => 0,
  getNextPageIndex: () => 0,
  reset: () => null,
  isSubmitting: false,
};

type ACTION_TYPE =
  { type: FORM_ACTIONS.SET_ACTIVE_PAGE; payload: IFormContextInitialState['activePage'] } |
  { type: FORM_ACTIONS.SET_DISABLED_PAGES; payload: IFormContextInitialState['disabledPages'] } |
  { type: FORM_ACTIONS.SET_FIELDS; payload: IFormContextInitialState['fields'] } |
  { type: FORM_ACTIONS.SET_SUBMITTING; payload: IFormContextInitialState['isSubmitting'] };

function reducer(state: IFormContextInitialState, action: ACTION_TYPE) {
  switch (action.type) {
    case FORM_ACTIONS.SET_ACTIVE_PAGE:
      return { ...state, activePage: action.payload < 0 ? 0 : action.payload };
    case FORM_ACTIONS.SET_DISABLED_PAGES:
      return { ...state, disabledPages: action.payload };
    case FORM_ACTIONS.SET_FIELDS: {
      const fields = action.payload.reduce((acc, field) => {
        if (field?.combinedFields) {
          const { combinedFields, ...parentField } = field;
          acc = [...acc, ...combinedFields.map(field => ({ ...field, parentField }))];
        } else {
          acc.push(field);
        }

        return acc;
      }, [] as IFormFields);

      return { ...state, fields };
    }
    case FORM_ACTIONS.SET_SUBMITTING:
      return { ...state, isSubmitting: action.payload };
    default:
      throw new Error();
  }
}

const FormContext = createContext<{
  state: IFormContextInitialState;
  dispatch: Dispatch<ACTION_TYPE>;
}>({
  state: initialState,
  dispatch: () => null,
});

const FormContextProvider = ({ children, reactHookFormProps, fields: initialFields }: IFormProviderProps) => {
  const [state, dispatch] = useReducer(reducer, { ...initialState, fields: initialFields });
  const { fields, disabledPages, activePage } = state;
  const formValidationSchema = useMemo(() => getFormValidationSchema(fields), [fields, fields.map(({ condition }) => condition)]);
  const getField = useCallback((name: string, formFields: IFormFields = fields) => getFormField(name, formFields, rcFormMethods.getValues()), [fields, fields.map(({ condition }) => condition)]);
  const groupedFields = useMemo(() => getGroupedFields(fields), [fields, initialFields?.length, state]);
  const pagesCount = useMemo(() => groupedFields.length - 1, [groupedFields]);
  const { schema, updateSchema } = useUpdateFormSchema(formValidationSchema);
  const defaultValues = useMemo(() => getFormDefaultValues(fields), [fields]);
  const getPreviousPageIndex = useCallback(() => getPreviousFormPageIndex(activePage, disabledPages), [activePage, disabledPages]);
  const getNextPageIndex = useCallback(() => getNextFormPageIndex(activePage, disabledPages), [activePage, disabledPages]);

  const rcFormMethods = useRCForm({
    defaultValues,
    resolver: yupResolver(schema) || undefined,
    ...reactHookFormProps,
  });
  const reset = () => {
    dispatch({ type: FORM_ACTIONS.SET_ACTIVE_PAGE, payload: 0 });
    rcFormMethods.reset(defaultValues);
  };

  useEffect(() => {
    dispatch({ type: FORM_ACTIONS.SET_FIELDS, payload: initialFields });
  }, [initialFields?.length]);

  useEffect(() => {
    updateSchema(formValidationSchema);
  }, [Object.keys(formValidationSchema.fields).join()]);

  return (
    <FormRCProvider {...rcFormMethods}>
      <FormContext.Provider value={{
        state: {
          ...state,
          fields,
          pagesCount,
          getField,
          groupedFields,
          updateSchema,
          getPreviousPageIndex,
          getNextPageIndex,
          reset,
        },
        dispatch,
      }}
      >
        {children}
      </FormContext.Provider>
    </FormRCProvider>
  );
};

const useFormContext = () => {
  const context = useContext(FormContext);
  const rcFormContext = useRCFormContext();

  if (context === undefined) {
    throw new Error('useFormContext must be used within a useFormContext');
  }

  return { ...context, ...rcFormContext };
};

export { FormContext, FormContextProvider, useFormContext };
