import * as yup from 'yup';
import type {
  FormField,
  FormFieldSingleValueObject,
  FormType,
  IFormContextInitialState,
  IFormFields,
} from '@/components/common/form/types';
import type { IFormattedSurveyAnswers, IGetValuesFromActiveFormSteps } from '@/components/common/form/utils/types';

export const getFieldsForPage = (fields: IFormFields, page: number) => fields.filter(field => field.step === page);

export const getGroupedFields = (fields: IFormFields) => fields.reduce((acc, field) => {
  const step = field.step || 0;

  if (!acc[step]) {
    acc[step] = [];
  }

  acc[step].push(field);
  return acc;
}, [] as FormType['fields'][]);

export const hasFormMultipleSteps = (groupedFields: FormType['fields'][]) => groupedFields.length > 1;

const fieldsValidationSchema = (fields: FormField[], parentCondition: FormField['condition'] = null) =>
  fields
    .filter(field => ('name' in field))
    .reduce((acc, {
      validation,
      name,
      condition = null,
      combinedFields = [],
    }) => {
      if (validation && (condition === null && parentCondition === null) && !combinedFields.length) {
        acc[name.replace('[]', '')] = validation;
      }

      if (combinedFields.length) {
        const combinedFieldsValidationSchema = fieldsValidationSchema(combinedFields, condition);

        acc = { ...acc, ...combinedFieldsValidationSchema };
      }

      return acc;
    }, {});

export const getFormValidationSchema = (formFields: FormType['fields']) => yup.object(fieldsValidationSchema(formFields));

export const getFormDefaultValues = <T = unknown>(formFields: FormType['fields']): Record<string, T> => {
  const flattenFields = (fields: FormType['fields']) =>
    fields
      .filter(field => 'name' in field)
      .reduce((acc, { name, defaultValue, nestedFields = [] }) => {
        if (!name) return acc;

        if (name.includes('.')) {
          const [mainKey, nestedKey] = name.split('.');
          acc[mainKey] = acc[mainKey] || {};
          acc[mainKey] = {
            ...acc[mainKey],
            [nestedKey]: defaultValue,
          };
        } else {
          acc[name] = defaultValue;
        }

        if (nestedFields.length > 0) {
          const nestedValues = flattenFields(nestedFields);

          Object.entries(nestedValues).forEach(([key, value]) => {
            if (typeof acc[key] === 'object' && value && typeof value === 'object') {
              acc[key] = { ...acc[key], ...value };
            } else {
              acc[key] = value;
            }
          });
        }

        return acc;
      }, {});

  return flattenFields(formFields);
};

export const getFieldMaxArrayValidation = (field: FormField) => {
  if (!field?.validation) return null;

  const { type, tests } = field.validation.describe();
  let maxValue = 0;

  if (type === 'array') {
    const maxField = tests.find(test => test.name === 'max');

    if (maxField) {
      maxValue = maxField.params.max as number;
    }
  }

  return maxValue;
};

export const getPreviousFormPageIndex = (index = 0, disabledPages: IFormContextInitialState['disabledPages'] = []) => {
  const prevIndex = index - 1;
  return disabledPages.includes(prevIndex) ? getPreviousFormPageIndex(prevIndex, disabledPages) : prevIndex;
};

export const getNextFormPageIndex = (index = 0, disabledPages: IFormContextInitialState['disabledPages'] = []) => {
  const nextIndex = index + 1;
  return disabledPages.includes(nextIndex) ? getNextFormPageIndex(nextIndex, disabledPages) : nextIndex;
};

export const getValuesFromActiveFormSteps = ({
  values = {},
  fields = [],
  disabledPages = [],
}: IGetValuesFromActiveFormSteps) => {
  const fieldsOnActivePage = fields.filter((field) => 'name' in field && field.name && typeof field.step === 'number' && !disabledPages.includes(field.step));
  const valuesKeys = Object.keys(values);

  return valuesKeys.reduce((acc, fieldName) => {
    const field = fieldsOnActivePage.find((field) => 'name' in field && field.name === fieldName);

    if (field) {
      acc[fieldName] = values[fieldName];

      if (field?.value && Array.isArray(field.value) && values[fieldName]) {
        field.value.forEach((value) => {
          value.nestedFields?.filter(field => 'name' in field).forEach((nestedField) => {
            const nestedFieldObject = getFormField(nestedField.name, fieldsOnActivePage);
            // todo: fix this
            // eslint-disable-next-line no-unsafe-optional-chaining
            const parentFieldName = nestedFieldObject.parentField && 'name' in nestedFieldObject?.parentField && nestedFieldObject.parentField.name;

            if (parentFieldName && nestedFieldObject.parentValue) {
              const parentValue = values[parentFieldName];
              const isParentFieldValueContainsNestedField = Array.isArray(parentValue) ? parentValue.includes(nestedFieldObject.parentValue.value) : nestedFieldObject.parentValue.value === parentValue;

              if (isParentFieldValueContainsNestedField) {
                acc[nestedField.name] = values[nestedField.name];
              }
            }
          });
        });
      }
    }
    return acc;
  }, {});
};

export const getFormField = (name: string, fields: IFormFields, formValues?: IGetValuesFromActiveFormSteps['values']) => {
  let searchedField: FormField;
  let parentValue: FormFieldSingleValueObject | undefined;
  let parentField: FormField | undefined;

  fields
    .filter(field => 'name' in field)
    .forEach((field) => {
      const { setCondition, parentField: { setCondition: setConditionParent } = {} } = field;

      if ((setCondition || setConditionParent) && formValues) {
        const conditionFunc = setConditionParent && setCondition ? setCondition : setConditionParent || setCondition;
        field.condition = conditionFunc?.(formValues);
      }

      if (field.name === name) {
        searchedField = field;
      } else if (Array.isArray(field.value)) {
        field.value.forEach((value) => {
          if (value?.nestedFields) {
            const { field: formField } = getFormField(name, value.nestedFields, formValues);

            if (formField) {
              parentField = field;
              parentValue = value;
              searchedField = formField;
            }
          }
        });
      }
    });

  return { field: searchedField!, parentValue, parentField };
};

export const getFormattedFormValues = ({ values, fields, disabledPages }: {
  values: IGetValuesFromActiveFormSteps['values'];
  fields: IFormFields;
  disabledPages: number[];
}) => {
  const result: IFormattedSurveyAnswers = [];
  values = getValuesFromActiveFormSteps({ values, fields, disabledPages });

  Object.entries(values)?.forEach(([fieldName, value]) => {
    if (Array.isArray(value) ? value.length : value) {
      const { field, parentField, parentValue } = getFormField(fieldName, fields, values);

      if (!parentValue) {
        const { hiddenLabel, step } = field;
        const question = hiddenLabel;

        result.push({
          question,
          answer: Array.isArray(value) ? value.reduce((acc, curr) => {
            acc[curr] = '';
            return acc;
          }, {}) : value!,
          page: step + 1,
        });
      } else {
        const parentResultIndex = result.findIndex(item => item.question === parentField?.hiddenLabel);
        const { answer } = result[parentResultIndex];

        if (typeof answer === 'string' && parentField && 'name' in parentField && parentField?.name) {
          const value = values[fieldName] as string;
          const key = values[parentField.name] as string;

          result[parentResultIndex]['answer'] = {
            [key]: value,
          };
        } else if (typeof answer === 'object' && parentValue.value in answer) {
          result[parentResultIndex].answer[parentValue.value] = values[fieldName];
        }
      }
    }
  });

  return result;
};
